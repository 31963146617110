import axios from "axios";
import axiosRetry from 'axios-retry';
import { ApiResponse } from "../../types/api/apiResponse.type";
import { getLocalStorageAccessToken } from "../localStorage.service";

export const handleResponse = <T>(apiResponse: ApiResponse<T>) => {
    if (!apiResponse.isSuccess || apiResponse.errorMessage) {
        throw new Error(apiResponse.errorMessage);
    }
    return apiResponse.result;
}

export const getImage = (imageUrl:string) => {
    axiosRetry(axios, { retries: 3 });
    
    return axios.get(imageUrl, {
        headers: {
            "Authorization": "Bearer " + getLocalStorageAccessToken()
        },
        responseType: "blob"
    }).then((response: any) => {
        let blob = new Blob([response.data]);
        return blob;
    });
}